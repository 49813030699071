import { useState } from "react";
import Button from "react-bootstrap/Button";
import { InformationModal } from "./Modals";
//, ConfigModal

import classes from "./TopIcons.module.css";

const TopIcons = (props) => {
  const [informationShow, setInformationShow] = useState(false);

  const handleInformationClose = () => setInformationShow(false);
  const handleInformationShow = () => setInformationShow(true);

  // const [configShow, setConfigShow] = useState(false);
  // const handleConfigClose = () => setConfigShow(false);
  // const handleConfigShow = () => setConfigShow(true);

  //      <Button variant={null} onClick={handleConfigShow}>
  //      <img src='/images/gear.png' alt='Configuration Options' width='32' /> 
  //    </Button>
   //   <ConfigModal show={configShow} onClose={handleConfigClose} />
  return (
    <div className={classes.toplinks}>
      <a className="btn" href={`/${props.values.dem.thirdParty}/${props.values.dem.stayHome}/${props.values.dem.voteOpposite}/${props.values.dem.incTurnout}/${props.values.rep.thirdParty}/${props.values.rep.stayHome}/${props.values.rep.voteOpposite}/${props.values.rep.incTurnout}`}>
        <img src='/images/link.png' alt='Link to this map' width='32' />
      </a>
      <Button variant={null} onClick={handleInformationShow}>
        <img src='/images/information.png' alt='Information about this application' width='32' /> 
      </Button>
      <InformationModal show={informationShow} onClose={handleInformationClose} />
    </div>
  );
};

export default TopIcons;
