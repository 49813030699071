import USAMap from 'react-usa-map';
import { getVoteColor } from '../utility/utilityFunctions';
import useWindowDimensions from '../utility/windowSize';

import classes from './Map.module.css';
 
const Map = (props) => {
    const windowDimensions = useWindowDimensions();
    const windowWidth = windowDimensions.width;
    const windowHeight = windowDimensions.height;

    const makeStatsConfig = () => {
        const config = {};

        props.InitialMap.map((StateObj) => {
            if (!(StateObj.name.includes("-"))) {
                config[StateObj.name] = {};
                config[StateObj.name].fill = getVoteColor(StateObj.democrats, StateObj.republicans, props.values);
            }
            return '';
        });

        return config;
    }

    const statesCustomConfig = makeStatsConfig();

    let isMobile = false;
    if (windowWidth < 575) {
        isMobile = true;
    }
    
    return (
        <div className={classes.map}>
            {isMobile ? <USAMap customize={statesCustomConfig} width={windowWidth} height={windowHeight/2} /> : undefined }
            {!isMobile ? <USAMap customize={statesCustomConfig} /> : undefined }
        </div>
    )
}

export default Map;