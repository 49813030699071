const ElectoralVoteSection = (props) => {
    const Change = props.totalEVs - props.originalEVs;

    return (
        <>
         {props.partyName}: {props.totalEVs} {Change !== 0 ?`(${Change > 0 ? '+' : ''}${Change})` : ''}
        </>
    )
}

export default ElectoralVoteSection;