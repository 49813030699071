import TopIcons from './TopIcons';

import classes from './Header.module.css';
import { Col, Container, Row } from 'react-bootstrap';

const Header = (props) => {
    return (
        <Container>
            <Row>
                <Col xs={12} md={8} className={classes.maintitle}>
                    Electoral Vote Simulator
                </Col>
                <Col xs={12} md={4} className="text-center">
                    <TopIcons values={props.values} />
                </Col>
            </Row>
        </Container>
    )
}

export default Header;