import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import MainPage from "./components/MainPage";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainPage />,
      children: [
        {
          path: ':demThirdPartyInitial/:demStayHomeInitial/:demVoteOppositeInitial/:demIncTurnout/:repThirdPartyInitial/:repStayHomeInitial/:repVoteOppositeInitial/:repIncTurnout',
          element: <MainPage />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />
}

export default App;
