import { Col, Container, Row, InputGroup } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';

import classes from './NumberInputBlock.module.css';
import { useRef } from 'react';

const NumberInputBox = (props) => {
    const inputValue = useRef(props.value);
    
    const isInteger = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseInt(str)) // ...and ensure strings of whitespace fail
      }


    const ChangeValue = (event) => {
        if (!(isInteger(inputValue.current.value))) {
            inputValue.current.value = props.value;
            return;
        }
        const newValue = parseInt(inputValue.current.value);
        
        if (newValue < props.min) {
            inputValue.current.value = props.value;
            return;
        }
        if (newValue > props.max) {
            inputValue.current.value = props.value;
            return;
        }
        props.onChange(newValue);
    }

    // const upArrowVariant = (props.value < props.max) ? 'outline-primary' : 'outline-disabled';
    // const downArrowVariant = (props.value > props.min) ? 'outline-primary' : 'outline-disabled';

    return (
        <Container>
            <Row>
                <Col className={classes.numberLabel}>
                    {props.label}
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-12">
                        {/* <Button
                            variant={upArrowVariant}
                            size="sm"
                            onClick={IncrementCounter}
                        >
                            ▴
                        </Button> */}
                        {/* <Form.Control
                            type="number"
                            placeholder=""
                            htmlSize="5"
                            htmlMin={props.min}
                            htmlMax={props.max}
                            className={classes.numberBlock}
                            defaultValue={props.value}
                            onChange={ChangeValue}
                        /> */}
                        <input
                            type="number"
                            ref={inputValue}
                            className="form-control"
                            step="1"
                            min={props.min}
                            max={props.max}
                            defaultValue={props.value}
                            onChange={ChangeValue}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                        {/* <Button
                            variant={downArrowVariant}
                            size="sm"
                            onClick={DecrementCounter}
                        >
                            ▾
                        </Button> */}
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default NumberInputBox;