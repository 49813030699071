import Header from './Header';
import Map from './Map';
import { useEffect, useState } from 'react';
import ElectoralVotes from './ElectoralVotes';

import { Initial_Map_State, InitializePathValue, calculateElectoralVotes } from '../utility/utilityFunctions';

import classes from './MainPage.module.css';
import MapSidebar from './MapSidebar';
import { useParams } from 'react-router-dom';
import SliderArea from './SliderArea';
import { Col, Container, Row } from 'react-bootstrap';


const MainPage = () => {
    const [totalValues, setTotalValues] = useState({
        dem: {
            thirdParty: 0,
            stayHome: 0,
            voteOpposite: 0,
            incTurnout: 0
        },
        rep: {
            thirdParty: 0,
            stayHome: 0,
            voteOpposite: 0,
            incTurnout: 0
        },
    });

    const params = useParams();
    useEffect(() => {        
        let initialValueObj = {
            dem: {
                thirdParty: InitializePathValue(params.demThirdPartyInitial),
                stayHome: InitializePathValue(params.demStayHomeInitial),
                voteOpposite: InitializePathValue(params.demVoteOppositeInitial),
                incTurnout: InitializePathValue(params.demIncTurnout)
            },
            rep: {
                thirdParty: InitializePathValue(params.repThirdPartyInitial),
                stayHome: InitializePathValue(params.repStayHomeInitial),
                voteOpposite: InitializePathValue(params.repVoteOppositeInitial),
                incTurnout: InitializePathValue(params.repIncTurnout)
            },
        };

        setTotalValues(initialValueObj);
    }, [params]);

    const MapData = Initial_Map_State();

    const handleChange = (newValue) => {
        setTotalValues(newValue);
    }

    const electoralVotes = calculateElectoralVotes(MapData, totalValues);

    return (
        <Container className={classes.mainContainer}>
            <Row>
                <Col>
                    <Header values={totalValues} />
                </Col>
            </Row>
            <Row>
                <SliderArea values={totalValues} onChange={handleChange} />
            </Row>
            <Row>
                <ElectoralVotes voteTotals={electoralVotes} />
            </Row>
            <Row>
                <Col xs={12} xl={9}>
                    <Map values={totalValues} InitialMap={MapData} />
                </Col>
                <Col xs={12} xl={3}>
                    <MapSidebar values={totalValues} InitialMap={MapData} />
                </Col>
                {/* <div className={classes.mapcontainer}>
                    <div className={classes.map}>
                        <Map values={totalValues} InitialMap={MapData} />
                    </div>
                    <div className={classes.mapsidebar}>
                        <MapSidebar values={totalValues} InitialMap={MapData} />
                    </div>
                </div> */}
            </Row>
        </Container>
    )
}

export default MainPage;